import { useAuth0 } from "@auth0/auth0-react";
import { FullScreenLoader } from "../FullScreenLoader/FullScreenLoader";

import "./LoadingWrapper.scss";

interface Props {
  children: JSX.Element;
}

export const LoadingWrapper = ({ children }: Props): JSX.Element => {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return <>{children}</>;
};
