import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ComponentType } from "react";
import { Navigate } from "react-router-dom";
import { Role } from "../../interfaces";
import { FullScreenLoader } from "../FullScreenLoader/FullScreenLoader";
import { Layout } from "../Layout/Layout";
import { SuspenseWithFallback } from "../SuspenseWithFallback/SuspenseWithFallback";

import "./AuthenticationGuard.scss";

interface Props {
  component: ComponentType<object>;
  roles: Role[] | undefined;
  userRole: Role[] | undefined;
  allowedRoles: Role[];
}

export const AuthenticationGuard = ({ component, roles, userRole, allowedRoles }: Props): JSX.Element => {
  const Component = withAuthenticationRequired(component, {});
  const { user } = useAuth0();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (roles && userRole) {
    const canAccess = allowedRoles.some((role) => role.id === userRole[0].id);
    if (canAccess) {
      return (
        <SuspenseWithFallback>
          <Layout>
            <Component />
          </Layout>
        </SuspenseWithFallback>
      );
    } else {
      return <Navigate to={"/dashboard"} />;
    }
  }
  return (
    <SuspenseWithFallback>
      <Layout>
        <FullScreenLoader />
      </Layout>
    </SuspenseWithFallback>
  );
};
