import { Suspense } from "react";
import { FullScreenLoader } from "../FullScreenLoader/FullScreenLoader";

import "./SuspenseWithFallback.scss";

interface Props {
  children: JSX.Element;
}

export const SuspenseWithFallback = ({ children }: Props): JSX.Element => {
  return <Suspense fallback={<FullScreenLoader />}>{children}</Suspense>;
};
