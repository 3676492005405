/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Role, UserRoles, User, Company } from "../interfaces";
import { Companies } from "../services/companies.service";
import { Roles } from "../services/roles.service";
import { Users } from "../services/users.service";

interface IUser {
  userWithRole: User | undefined;
  roles: Role[];
  isManager: boolean;
  handleClientSelect: (client: Company | undefined) => void;
  companies: Company[];
  selectedClient: Company | undefined;
  fetchClients: () => Promise<void>;
}

type Props = {
  children?: React.ReactNode;
};

const UserContext = React.createContext({} as IUser);

const UserContextProvider = ({ children }: Props) => {
  const [userWithRole, setUserWithRole] = useState<User>();
  const [selectedClient, setSelectedClient] = useState<Company>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isManager, setIsManager] = useState(false);
  const { user } = useAuth0();

  const fetchUser = async (): Promise<void> => {
    if (user && user.sub) {
      const currentUser = await Users.one(user.sub);

      try {
        const role = await Users.getRoles(user.sub);
        role[0].id === UserRoles.Manager.id && setIsManager(true);
        const userWithRole = { ...currentUser, role: role };
        userWithRole.user_metadata.company &&
          setSelectedClient(userWithRole.user_metadata.company);
        setUserWithRole(userWithRole);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchRoles = async () => {
    if (user) {
      const roles = await Roles.list();
      setRoles(roles);
    }
  };

  const fetchClients = async () => {
    if (user) {
      const response = await Companies.list();

      setCompanies(response);
    }
  };

  const handleClientSelect = (client: Company | undefined) => {
    setSelectedClient(client);
  };

  useEffect(() => {
    fetchUser().catch((error) => console.error(error));
    fetchRoles().catch((error) => console.log(error));
    fetchClients().catch((error) => console.log(error));
  }, [user?.sub]);

  const state = {
    userWithRole,
    roles,
    isManager,
    handleClientSelect,
    companies,
    selectedClient,
    fetchClients,
  };

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export { UserContextProvider, UserContext };
