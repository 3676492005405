import { Sidebar } from "../Sidebar/Sidebar";

import "./Layout.scss";

interface Props {
  children: JSX.Element;
}

export const Layout = ({ children }: Props): JSX.Element => {
  return (
    <div className="page">
      <div className="page__sidebar">
        <Sidebar />
      </div>
      <div
        id="page__content"
        className="page__content">
        {children}
      </div>
    </div>
  );
};
