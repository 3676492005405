import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import { useContext } from "react";
import { sidebarItems } from "./SidebarItems";
import { UserContext } from "../../contexts/user.context";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Company } from "../../interfaces";
import Typography from "@mui/material/Typography";
import { Logo } from "./Logo";

export const Sidebar = (): JSX.Element => {
  const { userWithRole, companies, selectedClient, handleClientSelect } =
    useContext(UserContext);

  const activeClassName = ({ isActive }: { isActive: boolean }): string =>
    isActive ? "navigation__link navigation__link--active" : "navigation__link";

  const handleCompanyChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    if (!value) {
      handleClientSelect(undefined);
      return;
    }
    handleClientSelect(JSON.parse(value) as Company);
  };

  return (
    <>
      <div className="logo">
        <Logo {...{ fill: "#ffffff" }} />
      </div>

      {userWithRole &&
        (userWithRole.user_metadata.role[0].name === "Installer" ||
          userWithRole.user_metadata.role[0].name === "admin") && (
          <div className="client-picker">
            <Select
              className="client-picker__select"
              IconComponent={(props) => (
                <svg
                  {...props}
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 1L9 8.5L1.5 1"
                    stroke="#649F56"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              displayEmpty
              onChange={handleCompanyChange}
              value={JSON.stringify(selectedClient) || ""}
              sx={{
                width: "100%",
                backgroundColor: "secondary.dark",
                borderRadius: "0.5rem",
                ".MuiOutlinedInput-notchedOutline": { border: "0 !important" },
              }}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(value) => {
                if (value === "") {
                  return (
                    <Typography
                      color="secondary.contrastText"
                      sx={{ fontWeight: 700 }}
                    >
                      Client
                    </Typography>
                  );
                }
                const company = JSON.parse(value) as Company;
                return company.name;
              }}
            >
              <MenuItem value={undefined}>
                <Typography color="secondary" sx={{ fontWeight: 700 }}>
                  Show All
                </Typography>
              </MenuItem>
              {companies.map((company) => (
                <MenuItem
                  sx={{ color: "secondary" }}
                  key={company.id}
                  value={JSON.stringify(company)}
                >
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

      <nav className="navigation">
        <ul className="navigation__list">
          {userWithRole &&
            sidebarItems(userWithRole.user_metadata.role).map((item) => (
              <li className="navigation__list-item" key={item.id}>
                <NavLink
                  end
                  className={(navData) => activeClassName(navData)}
                  to={item.path}
                >
                  {item.icon} {item.name}
                </NavLink>
              </li>
            ))}
        </ul>
      </nav>
    </>
  );
};
