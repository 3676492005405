export interface User {
  id?: string; // comes from DB (?)
  user_id?: string; // comes from Auth0
  email?: string;
  role?: Role[];
  user_metadata: {
    hiddenWidgets?: SensorData[];
    company?: Company | null;
    role: Role[];
  };
  password?: string;
  connection?: string;
  name?: string;
  nickname?: string;
  picture?: string;
}

export interface Role {
  id: string;
  name: string;
  description: string;
}

export interface Company {
  id: string;
  name: string;
}

export interface ClientData {
  pairid: number;
  email: string;
  clientid: number;
}

export interface SensorData {
  sensorid: number;
  uuid: string;
  moduleslotnumber: number;
  sensorname: string;
  dataindex: number;
  algorithm: number;
  isactive: boolean;
  datatype: string;
  alertid: number;
  vpin: number;
  warnmin: number | undefined;
  warnmax: number | undefined;
}

export interface Device {
  id: string;
  uuid: string;
  name: string;
  companyid: string;
}

export interface SensorHistoryTable {
  id: string;
  name: string;
  dataType: {
    id: string;
    dataType: string;
  };
  timestamp: string;
  sensorReading: number;
}

export interface AlertHistoryTable {
  id: string;
  name: string;
  timestamp: string;
  sensorReading: number;
  alertMessage: string;
  alertUsers: string[];
}

export interface TableAlerts {
  alertid: number;
  minimum: number | null;
  maximum: number | null;
  realertwait: number | null;
  alertcontactid: number | null;
  isactive: number | null;
  sensorid: number | null;
}

export class UserRoles {
  static readonly Admin = new UserRoles(
    "rol_XomisIlVrLfV6pqL",
    "admin",
    "Super admin, able to create any users, has all the permissions"
  );
  static readonly Installer = new UserRoles(
    "rol_R83h2Tt197I3J6nQ",
    "Installer",
    "Can setup new devices "
  );
  static readonly Manager = new UserRoles(
    "rol_19qwXDF3YDzypCAV",
    "manager",
    "Manager, able to create the users withing the company"
  );
  static readonly Viewer = new UserRoles(
    "rol_RyKxuQM9A3tKrFDj",
    "Viewer",
    "Can view data only "
  );

  private constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string
  ) {
    return {
      id: id,
      name: name,
      description: description,
    } as Role;
  }
}

export interface HiddenSensor {
  deviceid: string;
  type: string;
}

export interface HiddenSensors {
  userid: string;
  hiddendevices: HiddenSensor[];
}

export interface AlertContact {
  alertcontactid: number;
  name: string;
  clientid: number;
  email: string[];
  phone: string[];
}
