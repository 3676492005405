import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import axios from "axios";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_BASE_PORT}`,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    // Any status code from range of 2xx
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes outside range of 2xx
    // Do something with response error
    return Promise.reject(error);
  },
);

interface GetTokenSilentlyFunction {
  (options: GetTokenSilentlyOptions & { detailedResponse: true }): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyVerboseResponse | string>;
}

export const addAccessToken = (getAccessTokenSilently: GetTokenSilentlyFunction) => {
  http.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers["authorization"] = `Bearer ${token}`;
    return config;
  });
};

export default http;
