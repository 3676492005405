import http from "../utils/https";
import { ClientData, Role, User } from "../interfaces";

const USERS_ENDPOINT = "/api/v1/users";
const CLIENT_DATA_ENDPOINT = "/api/v1/data";

const list = async (): Promise<User[]> => {
  return http.get<User[]>(USERS_ENDPOINT).then((response) => response.data);
};

const one = async (userId: string): Promise<User> => {
  return http.get<User>(`${USERS_ENDPOINT}/${userId}`).then((response) => response.data);
};

const create = async (userData: User): Promise<User> => {
  return http
    .post<User>(USERS_ENDPOINT, {
      ...userData,
    })
    .then((response) => response.data);
};

const update = async (userId: string, userData: Partial<User>): Promise<User> => {
  return http.patch<User>(`${USERS_ENDPOINT}/${userId}`, { ...userData }).then((response) => response.data);
};

const remove = async (userId: string): Promise<void> => {
  return http.delete<void>(`${USERS_ENDPOINT}/${userId}`).then((response) => response.data);
};

const getClientData = async (email: string): Promise<ClientData[]> => {
  return http
    .get<ClientData[]>(`${CLIENT_DATA_ENDPOINT}?getclientid=true&email=${email}`)
    .then((response) => response.data);
};

const getRoles = async (userId: string): Promise<Role[]> => {
  return http.get<Role[]>(`${USERS_ENDPOINT}/${userId}/roles`).then((response) => response.data);
};

const assignRoles = async (userId: string, roleId: string): Promise<void> => {
  return http.post<void>(`${USERS_ENDPOINT}/${userId}/roles`, { roleId }).then((response) => response.data);
};

export const Users = {
  list,
  one,
  create,
  update,
  delete: remove,
  getClientData,
  getRoles,
  assignRoles,
};
