import { Role } from "../interfaces";
import http from "../utils/https";

const ROLES_ENDPOINT = "/api/v1/roles";

const list = async (): Promise<Role[]> => {
  return http.get<Role[]>(ROLES_ENDPOINT).then((response) => response.data.filter((role) => role.name !== "admin"));
};

export const Roles = {
  list,
};
