import { addAccessToken } from "./utils/https";
import { AuthenticationGuard } from "./components/AuthenticationGuard/AuthenticationGuard";
import { Navigate, Route, Routes } from "react-router-dom";
import { SuspenseWithFallback } from "./components/SuspenseWithFallback/SuspenseWithFallback";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect } from "react";
import "./App.scss";
import {
  AlertHistoryPage,
  AlertManagementPage,
  ClientManagementPage,
  DashboardPage,
  InstallerMenuPage,
  LoginPage,
  ReportPage,
  SensorDataHistoryPage,
  SingleSensorPage,
} from "./routes";
import { UserRoles } from "./interfaces";
import { UserContext } from "./contexts/user.context";

function App() {
  const { userWithRole, roles } = useContext(UserContext);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    addAccessToken(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SuspenseWithFallback>
            <LoginPage />
          </SuspenseWithFallback>
        }
      />

      {/* Protected routes */}

      <Route
        path="/dashboard"
        element={
          <AuthenticationGuard
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[
              UserRoles.Admin,
              UserRoles.Installer,
              UserRoles.Manager,
              UserRoles.Viewer,
            ]}
            component={DashboardPage}
          />
        }
      />

      <Route
        path="/dashboard/sensor/:id"
        element={
          <AuthenticationGuard
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[
              UserRoles.Admin,
              UserRoles.Installer,
              UserRoles.Manager,
              UserRoles.Viewer,
            ]}
            component={SingleSensorPage}
          />
        }
      />

      <Route
        path="/client-management"
        element={
          <AuthenticationGuard
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[
              UserRoles.Admin,
              UserRoles.Manager,
              UserRoles.Installer,
            ]}
            component={ClientManagementPage}
          />
        }
      />

      <Route
        path="/installer-menu"
        element={
          <AuthenticationGuard
            component={InstallerMenuPage}
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[
              UserRoles.Admin,
              UserRoles.Installer,
              UserRoles.Manager,
            ]}
          />
        }
      />

      <Route
        path="/alert-management"
        element={
          <AuthenticationGuard
            component={AlertManagementPage}
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[UserRoles.Admin, UserRoles.Manager]}
          />
        }
      />

      <Route
        path="/alert-history"
        element={
          <AuthenticationGuard
            component={AlertHistoryPage}
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[
              UserRoles.Admin,
              UserRoles.Installer,
              UserRoles.Manager,
              UserRoles.Viewer,
            ]}
          />
        }
      />

      <Route
        path="/sensor-data-history"
        element={
          <AuthenticationGuard
            component={SensorDataHistoryPage}
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[
              UserRoles.Admin,
              UserRoles.Installer,
              UserRoles.Manager,
              UserRoles.Viewer,
            ]}
          />
        }
      />

      <Route
        path="/report"
        element={
          <AuthenticationGuard
            component={ReportPage}
            roles={roles}
            userRole={userWithRole?.user_metadata.role}
            allowedRoles={[
              UserRoles.Admin,
              UserRoles.Installer,
              UserRoles.Manager,
              UserRoles.Viewer,
            ]}
          />
        }
      />

      {/* Default route */}

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default App;
