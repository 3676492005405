import { createTheme } from "@mui/material/styles";
import "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
  interface Palette {
    lightGrey: Palette["primary"];
    darkGrey: Palette["primary"];
  }

  interface PaletteOptions {
    lightGrey: PaletteOptions["primary"];
    darkGrey: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    darkGrey: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    darkGrey: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#649F56",
      contrastText: "#FFF",
      dark: "#003965",
      light: "#F4F9F3",
    },
    secondary: {
      main: "#003965",
      contrastText: "#FFF",
      light: "#fff",
      dark: "#F4F7FE",
    },
    error: { main: "#EE5D50" },
    warning: { main: "#FFAF02" },
    info: { main: "#608DFF" },
    success: { main: "#5DC637" },
    lightGrey: { main: "#f2f6ff" },
    darkGrey: { main: "#a3aed0" },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'DM Sans', sans-serif",
    h1: {
      fontSize: 96,
    },
    h2: {
      fontSize: 64,
    },
    h3: {
      fontSize: 32,
    },
  },
});
