import { SVGProps } from "react";

export const Logo = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    {...props}
    id="mode40-logo"
    xmlns="http://www.w3.org/2000/svg"
    width="225"
    height="55"
    viewBox="0 0 142 27"
  >
    <path
      id="wordmark"
      d="M131.176 8.918c0-2.716 1.393-4.545 3.889-4.545 2.443 0 3.888 1.75 3.888 4.545v4.544c0 2.769-1.209 4.546-3.888 4.546-2.6 0-3.889-1.7-3.889-4.546zm-3.047.157v4.231c0 4.832 2.732 7.392 6.936 7.392S142 18.138 142 13.306V9.075c0-4.7-2.732-7.392-6.935-7.392s-6.936 2.69-6.936 7.392m-13.787 4.884h6.148V4.686h-.315zm9.2-11.91v11.91h3.048V16.7h-3.048v3.631h-3.052V16.7h-9.326v-2.636l7.592-12.015zM98.949 12.574a3.3 3.3 0 013.494-3.029 3.151 3.151 0 013.363 3.029zm-3 1.254c0 4.048 2.679 6.87 6.62 6.87a6.211 6.211 0 005.859-3.395l-2.391-1.281a3.472 3.472 0 01-3.415 2.194 3.555 3.555 0 01-3.7-3.422h9.825v-1.175c0-3.944-2.522-6.555-6.279-6.555-3.809 0-6.515 2.742-6.515 6.764m-12.993.053c0 2.743 1.63 4.335 3.889 4.335s3.914-1.619 3.914-4.335-1.654-4.336-3.913-4.336-3.889 1.619-3.889 4.336M93.6 20.332h-2.836v-1.907H90.5a4.969 4.969 0 01-4.491 2.275c-3.179 0-5.938-2.612-5.938-6.817 0-4.18 2.759-6.792 5.938-6.792a4.976 4.976 0 014.44 2.169h.263V2.049H93.6zm-25.735-6.451c0-2.639 1.576-4.284 3.862-4.284 2.259 0 3.836 1.619 3.836 4.284s-1.577 4.309-3.836 4.309c-2.286 0-3.862-1.619-3.862-4.309m-2.89 0a6.739 6.739 0 1013.478 0 6.739 6.739 0 10-13.478 0M46.889 7.455v1.62h.263a3.619 3.619 0 013.52-1.854 3.688 3.688 0 013.652 2.141h.262A3.915 3.915 0 0158.4 7.221a4.167 4.167 0 014.361 4.544v8.567h-2.89v-8.411a2.163 2.163 0 00-2.371-2.35c-1.628 0-2.653 1.071-2.653 3.029v7.732h-2.89v-8.411a2.15 2.15 0 00-2.357-2.35c-1.629 0-2.654 1.071-2.654 3.029v7.732h-2.868V7.455z"
      // fill="#ffffff"
    ></path>
    <path
      id="icon"
      d="M0 9v6h1.508V9zm16.6 1.5h1.509v3H16.6zM15.087 9v6h4.526V9zm18.106 0v6H34.7V9zM0 6v1.5h3.017v9H0V27h7.543v-3h6.036v-1.5H6.035v3H1.508V18h3.018V6zm12.058 0v12H16.6v-1.5h-3.03v-9h7.568v9h-3.033V18h4.538V6zm19.625 0h-1.508v12h3.018v7.5h-4.527v-3h-7.544V24h6.035v3H34.7V16.5h-3.017v-9H34.7V6zM0 0v4.5h6.035v15H3.017V24h1.509v-3h3.017V3H1.508V1.5h13.579V3H9.052v18h6.035v4.5H9.052V27H16.6v-7.5h-6.039v-15H16.6V0zm18.105 0v4.5h6.035v15h-6.035V27h7.544v-1.5h-6.036V21h6.036V3h-6.036V1.5h13.58V3h-6.036v18h3.018v3h1.508v-4.5h-3.017v-15H34.7V0z"
      // fill="#ffffff"
    ></path>
  </svg>
);
