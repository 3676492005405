import { lazy } from "react";

export const AlertHistoryPage = lazy(() => import("./../pages/AlertHistory/AlertHistory"));
export const AlertManagementPage = lazy(() => import("./../pages/AlertManagement/AlertManagement"));
export const ClientManagementPage = lazy(() => import("./../pages/ClientManagement/ClientManagement"));
export const DashboardPage = lazy(() => import("./../pages/Dashboard/Dashboard"));
export const InstallerMenuPage = lazy(() => import("./../pages/InstallerMenu/InstallerMenu"));
export const LoginPage = lazy(() => import("./../pages/Login/Login"));
export const ReportPage = lazy(() => import("./../pages/Report/Report"));
export const SensorCardPage = lazy(() => import("../pages/Dashboard/SensorCard/SensorCard"));
export const SensorDataHistoryPage = lazy(() => import("./../pages/SensorDataHistory/SensorDataHistory"));
export const SingleSensorPage = lazy(() => import("../pages/Dashboard/SingleSensor/SingleSensor"));
